<template>
  <div>
    <Navgation bgColor="#060B4C" :isPageHome="true" />
    
    <div class="product-sjsyszc-container" v-if="!isMobile">
      <!-- banner -->
      <BannerHm />
              
      <Qyhm />

      
      <section class="service">
        <div
          class="service-content"
          :class="{
            'pre-hide-100': true,
            'move-bottom-100': isShowService,
          }"
        >
          <div class="service-title section-title">公司核名注意事项</div>
          <div class="service-module">
            <div
              @mouseover="serviceHoverIndex = serviceModuleIndex"
              class="service-module-item"
              v-for="(serviceModuleItem, serviceModuleIndex) in serviceModules"
              :key="serviceModuleItem.title"
            >
              <div class="service-module-item-content" >
                <img :src="serviceModuleItem.img" alt="" />
                <div class="service-module-item-content-title">
                  {{ serviceModuleItem.title }}
                </div>
                <div class="service-module-item-content-label">
                  {{ serviceModuleItem.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="qyhm-wrapper-mobile" v-else>
      <BannerHmMobile />
      <QyhmMobile />
      <section class="service zysx-mobile">
        <div
          class="service-content "
          :class="{
            // 'pre-hide-100': true,
            // 'move-bottom-100': isShowService,
          }"
        >
          <div class="service-title section-title">公司核名注意事项</div>
          <div class="service-module">
            <div
              class="service-module-item"
              v-for="(serviceModuleItem) in serviceModules"
              :key="serviceModuleItem.title"
            >
              <div class="service-module-item-content" >
                <img :src="serviceModuleItem.img" alt="" />
                <div class="service-module-item-content-title">
                  {{ serviceModuleItem.title }}
                </div>
                <div class="service-module-item-content-label">
                  {{ serviceModuleItem.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import * as componentList from '@/base'
import Navgation from '@/layouts/components/Navgation'
import partnerApi from '@/api/partner.api.js'
import {pageSeoInfo} from '@/config'
import checkIsMobile from '@/mixin/checkIsMobile'
export default {
  name: 'yszc',
  components: { Navgation, ...componentList },
  inject: ['getPosition', 'scrollTo'],
  mixins: [checkIsMobile],
  data() {
    return {
      joinForm: {
        name: '',
        mobile: '',
        city: '',
        type: '公司注册'
      },
      serviceModules: [
        {
          title: '不能重名',
          label: '相同或相似行业存在重名公司，不能注册。如：“新浪网络科技”与“新浪信息科技”算重名。',
          img: require('@/assets/images/gshm/icon-1.png'),
        },
        {
          title: '不可触犯驰名商标',
          label: '如：“万达”、“李宁”是驰名商标，不能注册为公司名字。',
          img: require('@/assets/images/gshm/icon-2.png'),
        },
        {
          title: '不可使用名人字号',
          label: '如：“马云”、“王健林”等名人不能注册为公司名字。',
          img: require('@/assets/images/gshm/icon-4.png'),
        },
        {
          title: '不可使用行业通用词汇',
          label: '如：“江苏网络科技有限公司”不能注册为公司名字。',
          
          img: require('@/assets/images/gshm/icon-3.png'),
        },
      ],
      serviceHoverIndex: 0,
      isShowService: false,
      isShowGszc: false,
      isShowAdvantage: false,
      isShowXD: false,
      isShowXD2: false,
      isShowAdsorption: false,
    }
  },
  mounted() {
    this.isShowService = true
    window.addEventListener('scroll', this.onScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, true)
  },
  methods: {
    onScroll() {
      const { scrollTop } = this.getPosition()
      const clientHeight = document.documentElement.clientHeight
      if (clientHeight <= 1150) {
        if (scrollTop >= 3700) {
          this.isShowAdvantage = true
        } else if (scrollTop >= 900) {
          this.isShowGszc = true
        }
      } else {
        if (scrollTop >= 3950) {
          this.isShowAdvantage = true
        } else if (scrollTop >= 1150) {
          this.isShowGszc = true
        }
      }
      if (scrollTop >= 600) {
        this.isShowXD2 = true;
        this.isShowXD = false;
        if (this.scrollTimer) {
          clearTimeout(this.scrollTimer);
          this.scrollTimer = null;
        }
        this.oldScroll = scrollTop;
        this.scrollTimer = setTimeout(() => {
          this.handleScroll(scrollTop);
        }, 700);
      } else {
        this.oldScroll = 0;
        this.newScroll = 0;
        this.isShowXD2 = true;
        this.isShowXD = false;
      }
    },
    handleScroll(scrollTop) {
      if (!this.isShowAdsorption) {
        this.isShowAdsorption = true;
      }
      this.newScroll = scrollTop;
      if (this.newScroll == this.oldScroll) {
        this.isShowXD2 = false;
        this.isShowXD = true;
        clearTimeout(this.scrollTimer);
        this.scrollTimer = null;
        this.oldScroll = 0;
        this.newScroll = 0;
      }
    },
  },
  metaInfo: {
    title: pageSeoInfo.product.title,
    meta: [{
      name: 'keywords',
      content: pageSeoInfo.product.keywords
    },
    {
      name: 'description',
      content: pageSeoInfo.product.description
    }]
  },
}
</script>
<style lang="less" scoped>
.product-sjsyszc-container,.qyhm-wrapper-mobile {
  // margin-top: @navHeight;
  
  .service {
    height: 700px;
    background: #f6f9fe;
    &.zysx-mobile{
      width: 750px;
      height: 1266px;
      .service-content{
        width: 750px;
      }
      .service-title {
        margin-top: 156px;
        margin-bottom: 156px;
        
      }
      .service-module {
          width: 750px;
          margin-top: 0px;
          &-item {
            flex-shrink: 1;
            width: 330px;
            height: 366px;
            margin-right: 30px;
            margin-bottom: 30px;
            &:nth-of-type(2n) {
              margin-right: 0px;
            }
            &:nth-of-type(4n) {
              .service-module-item-content {
                padding: 0 25px;
                padding-top: 43px;
              }
            }
            &-content {
              &-title {
                font-size: 28px;
                margin-top: 28px;
                margin-bottom: 28px;
              }
              &-label {
                font-size: 24px;
                line-height: 34px;
              }
            }
          }
       }
    }
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .service-title {
        margin-top: 136px;
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #102a55;
      }
      .service-label {
        margin-top: 20px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(16, 42, 85, 0.6);
      }
      .service-module {
        margin-top: 100px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        &-item {
          width: 240px;
          height: 275px;
          background: #ffffff;
          border-radius: 22px;
          margin-right: 24px;
          &:nth-of-type(4n) {
            margin-right: 0px;
          }
          &-content {
            // height: 100%;
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            // justify-content: center;
            padding: 0 28px;
            padding-top: 43px;
            img {
              display: block;
              width: 56px;
              height: 56px;
              margin: 0 auto;
            }
            &-title {
              margin-top: 10px;
              margin-bottom: 16px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: rgba(16, 42, 85, 1);
              text-align: center;
            }
            &-label {
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #102A55;
              opacity: 0.5;
              line-height: 20px;
              text-align: center;
            }
          }
        }
        &-hover-item {
          background: #2871ff;
          &-content {
            padding: 36px;
            box-sizing: border-box;
            &-line {
              margin-top: 6px;
              margin-bottom: 30px;
              width: 58px;
              height: 1px;
              background: #ffffff;
            }
            &-text {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  
  
  
  .pre-hide-100 {
    transform: translateY(100px);
    opacity: 0;
  }
  .pre-hide-300 {
    transform: translateY(300px);
    opacity: 0;
  }
  .move-bottom-100 {
    animation: move-bottom-100 1s 0.2s forwards ease-in-out;
  }
  .move-bottom-100-1 {
    animation: move-bottom-100-1 1s 0.2s forwards ease-in-out;
  }
  .move-bottom-300 {
    animation: move-bottom-300 1s 0.2s forwards ease-in-out;
  }
  @keyframes move-bottom-100 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes move-bottom-100-1 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes move-bottom-300 {
    0% {
      transform: translateY(300px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}
.option-cell2 {
    .name-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 22px;
      .name-txt {
        width: 146px;
        height: 51px;
        padding-top: 13px;
        box-sizing: border-box;
        flex-shrink: 1;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        cursor: pointer;
      }
      .name2 {
        margin: 0 10px;
      }
      .active {
        color: #060b4c;
        background-image: url("../assets/images/2.0/name-bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
    &.prefixed {
      animation: option-top-hide 1s forwards ease-in-out;
    }
    &.fixed {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 13;
      width: 100%;
      height: 106px;
      background: rgba(6, 11, 76, 0.5);
      transform: translateY(-300px);
      .name-cell {
        padding-top: 40px;
        margin-bottom: 22px;
      }
      .form-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 24px;
        .qm-cell,
        .hm-cell {
          height: 68px;
          padding-left: 5px;
          .form-item {
            height: 34px;
            &.form-ml {
              margin-left: 10px;
            }
            &.form-mr {
              margin-right: 10px;
            }
          }
          .submit-btn {
            margin-right: 15px;
            cursor: pointer;
          }
        }
        .cm-cell {
          height: 68px;
          // padding-left: 5px;
          .form-item {
            height: 42px;
          }
          .submit-btn {
            margin-right: 15px;
          }
        }
        .hm-cell {
          width: auto;
          .form-txt {
            margin-right: 10px;
          }
          .submit-btn {
            margin-right: 15px;
            margin-left: 15px;
          }
        }
        .submit-btn {
          height: 42px;
        }
        .hm-cell {
          display: flex;
          justify-content: center;
          align-items: center;
          // min-width: 870px;
          height: 58px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 17px;
          backdrop-filter: blur(25px);
          // padding: 0 3px;
          .form-item {
            width: 240px;
            height:34px;
            // background: #FFFFFF;
            border-radius: 9px;
            flex-shrink: 1;

            &.form-ml {
              margin-left: 12px;
            }
            &.form-mr {
              margin-right: 15px;
            }
            .el-select,
            .el-cascader {
              width: 100%;
              height: 100%;
              /deep/ .el-input {
                height: 100%;
                // background: transparent;
                .el-input__inner {
                  height: 100%;
                  // background: transparent;
                  font-size: 15px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #060b4c;
                  // border: 1px solid rgba(255, 255, 255, 0.25);
                  border-radius: 9px;
                  &::placeholder {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(16, 42, 85, 0.4);
                    // color: red;
                    letter-spacing: -1px;
                  }
                }
                .el-input__suffix {
                  // color: #fff;
                  color: rgba(26, 26, 26, 1);
                  .el-input__icon {
                    // color: #fff;
                    color: rgba(26, 26, 26, 1);
                  }
                }
              }
            }
            .el-input {
              width: 100%;
              height: 100%;
              /deep/ .el-input__inner {
                height: 100%;
                // background: transparent;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #060b4c;
                // border: 1px solid rgba(255, 255, 255, 0.25);
                border-radius: 9px;
                &::placeholder {
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(16, 42, 85, 0.4);
                }
              }
            }
          }
        }
        .hm-cell {
          width: 870px;
          padding: 0;
          margin: 0 auto;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 15px;
          box-sizing: border-box;
          .form-txt {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }
    }
    .submit-btn {
      width: 160px;
      height: 50px;
      margin: 0 auto;
      background: #2871ff;
      border-radius: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .move-top {
    animation: option-top 0.5s forwards ease-in-out;
  }
  .move-hide {
    animation: option-top-hide 0.5s forwards ease-in-out;
  }
  @keyframes option-top {
  0% {
    transform: translateY(-300px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes option-top-hide {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-300px);
  }
}
</style>
