var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navgation',{attrs:{"bgColor":"#060B4C","isPageHome":true}}),(!_vm.isMobile)?_c('div',{staticClass:"product-sjsyszc-container"},[_c('BannerHm'),_c('Qyhm'),_c('section',{staticClass:"service"},[_c('div',{staticClass:"service-content",class:{
          'pre-hide-100': true,
          'move-bottom-100': _vm.isShowService,
        }},[_c('div',{staticClass:"service-title section-title"},[_vm._v("公司核名注意事项")]),_c('div',{staticClass:"service-module"},_vm._l((_vm.serviceModules),function(serviceModuleItem,serviceModuleIndex){return _c('div',{key:serviceModuleItem.title,staticClass:"service-module-item",on:{"mouseover":function($event){_vm.serviceHoverIndex = serviceModuleIndex}}},[_c('div',{staticClass:"service-module-item-content"},[_c('img',{attrs:{"src":serviceModuleItem.img,"alt":""}}),_c('div',{staticClass:"service-module-item-content-title"},[_vm._v(" "+_vm._s(serviceModuleItem.title)+" ")]),_c('div',{staticClass:"service-module-item-content-label"},[_vm._v(" "+_vm._s(serviceModuleItem.label)+" ")])])])}),0)])])],1):_c('div',{staticClass:"qyhm-wrapper-mobile"},[_c('BannerHmMobile'),_c('QyhmMobile'),_c('section',{staticClass:"service zysx-mobile"},[_c('div',{staticClass:"service-content",class:{
          // 'pre-hide-100': true,
          // 'move-bottom-100': isShowService,
        }},[_c('div',{staticClass:"service-title section-title"},[_vm._v("公司核名注意事项")]),_c('div',{staticClass:"service-module"},_vm._l((_vm.serviceModules),function(serviceModuleItem){return _c('div',{key:serviceModuleItem.title,staticClass:"service-module-item"},[_c('div',{staticClass:"service-module-item-content"},[_c('img',{attrs:{"src":serviceModuleItem.img,"alt":""}}),_c('div',{staticClass:"service-module-item-content-title"},[_vm._v(" "+_vm._s(serviceModuleItem.title)+" ")]),_c('div',{staticClass:"service-module-item-content-label"},[_vm._v(" "+_vm._s(serviceModuleItem.label)+" ")])])])}),0)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }